import api from './api';
import toast from './toast';
import session from './session';
import ModalConfirmacion from './ModalConfirmacion';
import FormatoFecha from './FormatoFecha';
import filters from './filters';
import PaginateButtonGroup from './PaginateButtonGroup';
import TablePaginate from './TablePaginate';
import FormComponent from './FormComponent';
import mapaUtils from './mapaUtils';
import utils from './utils';
import MyDropZone from './MyDropZone';
import Validation from './Validation';
import InputGroupFile from './InputGroupFile';
import MySearchAutocomplete from './MySearchAutocomplete';
import ModalConfirmacionV2 from './ModalConfirmacionV2';
import ModalPreviewDocument from './ModalPreviewDocument';
import ErrorFocus from './ErrorFocus';
import MyBreadcrumb from './MyBreadcrumb';
import LogoDefault from '../assets/img/_icono_app.png';
// import LogoDefault from '../assets/img/_icono_app_simpo_2021.png';
import DefaultProfileImage from '../assets/img/man.png';

const ButtonSubmit = FormComponent.ButtonSubmit;
const MyLink = FormComponent.MyLink;
const LoaderComponent = FormComponent.LoaderComponent;
const ConfirmModal = FormComponent.ConfirmModal;
const PermisoWrapper = FormComponent.PermisoWrapper;
const MyAutocomplete = FormComponent.MyAutocomplete;

export { 
    api, 
    toast, 
    session, 
    ModalConfirmacion, 
    FormatoFecha, 
    filters, 
    PaginateButtonGroup,
    TablePaginate,
    ButtonSubmit,
    MyLink,
    LoaderComponent,
    mapaUtils,
    utils,
    MyDropZone,
    ConfirmModal,
    PermisoWrapper,
    Validation,
    InputGroupFile,
    MyAutocomplete,
    MySearchAutocomplete,
    ModalConfirmacionV2,
    ModalPreviewDocument,
    ErrorFocus,
    LogoDefault,
    DefaultProfileImage,
    MyBreadcrumb,
};
